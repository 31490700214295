 /* {maxHeight: "17px",overflow: "hidden"}} */
.dropzoneClass{
  max-height: 74px  !important;
  min-height: 15px !important;
  
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
  }